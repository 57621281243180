<template>
  <div class="ib-company-register-vue">
    <!--      steps-->
    <div class="bg-white lg:border-t lg:border-b lg:border-gray-200 mx-auto">
      <nav class="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
        <ul
          class="rounded-md overflow-hidden lg:flex lg:border-l lg:border-r lg:border-gray-200 lg:rounded-none"
        >
          <li
            v-for="(step, index) in step_meta"
            :key="index"
            class="relative overflow-hidden lg:flex-1"
          >
            <div
              class="border border-gray-200 overflow-hidden border-b-0 rounded-t-md lg:border-0"
            >
              <a class="group">
                <div
                  class="absolute top-0 left-0 w-1 h-full bg-transparent lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                ></div>
                <div
                  class="px-6 py-5 flex items-start text-sm leading-5 font-medium space-x-4"
                >
                  <div
                    v-if="index === +current_step"
                    class="absolute top-0 left-0 w-1 h-full bg-indigo-600 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                  ></div>
                  <div v-if="index < step" class="flex-shrink-0">
                    <div
                      class="w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full"
                    >
                      <!-- Heroicon name: check -->
                      <svg
                        class="w-6 h-6 text-white"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          clip-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          fill-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  <div v-else class="flex-shrink-0">
                    <div
                      :class="
                        index === +current_step
                          ? 'border-indigo-600'
                          : 'border-gray-300'
                      "
                      class="w-10 h-10 flex items-center justify-center border-2 rounded-full"
                    >
                      <p
                        :class="
                          index === +current_step
                            ? 'text-indigo-600'
                            : 'text-gray-400'
                        "
                      >
                        {{ index + 1 }}
                      </p>
                    </div>
                  </div>
                  <div class="mt-0.5 min-w-0">
                    <h3
                      :class="
                        index === +current_step ? 'active-font-color' : ''
                      "
                      class="text-xs leading-4 font-semibold uppercase tracking-wide"
                    >
                      <span v-if="index <= +current_step">
                        {{ step[0].title }}
                      </span>
                      <span v-else>
                        {{ step[0].title }}
                      </span>
                    </h3>
                  </div>
                </div>
              </a>
            </div>
            <div
              v-if="0 < index"
              class="hidden absolute top-0 left-0 w-3 inset-0 lg:block"
            >
              <svg
                class="h-full w-full text-gray-300"
                fill="none"
                preserveAspectRatio="none"
                viewBox="0 0 12 82"
              >
                <path
                  d="M0.5 0V31L10.5 41L0.5 51V82"
                  stroke="currentcolor"
                  vector-effect="non-scaling-stroke"
                />
              </svg>
            </div>
          </li>
        </ul>
      </nav>
    </div>

    <!--      Forms-->
    <div class="max-w-3xl mx-auto min-h-screen">
      <div class="mt-10 pb-10">
        <Form
          v-for="(step, index) in step_meta"
          v-if="+index === +current_step"
          :id="index"
          :key="index"
          :demo-form="false"
          :forms="step[0].fields"
          :numberOfSteps="step_meta.length - 1"
          :step="step"
          classes="shadow rounded-md"
          form-type="IB-company"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Form from "@/views/register/Form";
import store from "@/store";

export default {
  name: "CompanyForms",
  data() {
    return {
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };
  },
  components: {
    Form,
  },
  computed: {
    current_step() {
      if (!localStorage.getItem("step")) {
        localStorage.setItem("step", "0");
      }
      return this.$store.getters.show_next_step;
    },
    step_meta() {
      const t = this.$t.bind(this);
      let fields = [
        [
          {
            kind: "title",
            type: "title",
            title: t("company-details"),
            fields: [
              {
                field: "company_name",
                key: "company_name",
                label:
                  this.whitelabel === "PolarisMarkets"
                    ? "Full Corporation Name"
                    : t("company-name"),
                placeholder: t("company-name"),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "company_name",
              },
              {
                field: "website",
                key: "website",
                label: t("company-website"),
                placeholder: "http://www.company.com",
                kind: "url",
                type: "url",
                needed: true,
                fieldId: "website",
              },
              {
                field: "addr_country_ib",
                key: "country",
                label: t("Country"),
                placeholder: t("country-name"),
                kind: "select",
                type: "select",
                needed: true,
                country: true,
                fieldId: "country",
                choices: store.getters.get_countries,
              },
              {
                field: "addr_zip",
                key: "addr_zip",
                label: "Postcode",
                placeholder: "Postcode",
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "azip",
              },
              {
                field: "addr_line_1",
                key: "addr_line_1",
                label: t("address-line-1"),
                placeholder: t("address-line-1"),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "addr1",
              },
              {
                field: "addr_line_2",
                key: "addr_line_2",
                label: t("address-line-2"),
                placeholder: t("address-line-2"),
                kind: "text",
                type: "text",
                needed: false,
                fieldId: "addr2",
              },
              {
                field: "addr_city",
                key: "addr_city",
                label: t("city-or-town"),
                placeholder: t("city-or-town"),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "city",
              },
              {
                field: "tax_id",
                key: "tax_id",
                label: "Tax ID (VAT ID)",
                placeholder: "Tax ID (VAT ID)",
                kind: "text",
                type: "text",
                needed: false,
                fieldId: "comment",
              },
            ],
          },
        ],
        [
          {
            kind: "title",
            type: "title",
            title: t("personal-details"),
            fields: [
              {
                field: "title",
                key: "title",
                label: "Title",
                placeholder: "Title",
                kind: "select",
                type: "select",
                needed: true,
                fieldId: "title",
                choices: [
                  { value: 1, display_name: t("Mr") },
                  { value: 2, display_name: t("Ms") },
                  {
                    value: 3,
                    display_name: t("Mrs"),
                  },
                  { value: 4, display_name: t("Miss") },
                  { value: 5, display_name: t("Dr") },
                  {
                    value: 6,
                    display_name: t("Other"),
                  },
                ],
              },
              {
                field: "first_name",
                key: "first_name",
                label: t("first-name-20db0bfeecd8fe60533206a2b5e9891a"),
                placeholder: t("first-name-20db0bfeecd8fe60533206a2b5e9891a"),
                kind: "text",
                type: "text",
                needed: true,
                half: true,
                fieldId: "fname",
              },
              {
                field: "last_name",
                key: "last_name",
                label: t("last-name-8d3f5eff9c40ee315d452392bed5309b"),
                placeholder: t("last-name-8d3f5eff9c40ee315d452392bed5309b"),
                kind: "text",
                type: "text",
                needed: true,
                half: true,
                fieldId: "lname",
              },
              {
                field: "email",
                key: "email",
                label: t("Email"),
                placeholder: t("Email"),
                kind: "email",
                type: "email",
                needed: true,
                fieldId: "email",
              },
              {
                field: "password",
                key: "password",
                label: t("please-choose-a-password"),
                placeholder: t("please-choose-a-password"),
                kind: "password",
                type: "password",
                icon: "icon-password",
                needed: true,
                fieldId: "password",
              },
              {
                field: "password_confirmation",
                key: "password2",
                label: t("password-confirmation"),
                placeholder: t("enter-password-again-to-confirm"),
                kind: "password",
                type: "password",
                icon: "icon-password",
                needed: true,
                fieldId: "pconfirmation",
              },
              {
                field: "date_of_birth",
                key: "date_of_birth",
                label: "Date of birth",
                placeholder: "DD / MM / YYYY",
                kind: "date_of_birth",
                type: "text",
                needed: true,
                fieldId: "dobirth",
              },
              {
                field: "skype",
                key: "skype",
                label: t("skype-id-optional"),
                placeholder: t("skype-id"),
                kind: "text",
                type: "text",
                needed: false,
                fieldId: "skype",
              },
              {
                field: "telephone",
                key: "telephone",
                label: t("phone-number-1f8261d17452a959e013666c5df45e07"),
                placeholder: t("phone-number-1f8261d17452a959e013666c5df45e07"),
                kind: "telephone",
                type: "telephone",
                needed: true,
                fieldId: "phone",
              },
              {
                field: "addr_country_ib",
                key: "addr_country",
                label: t("Country"),
                placeholder: t("country-name"),
                kind: "select",
                type: "select",
                needed: true,
                country: true,
                fieldId: "country",
                choices: store.getters.get_countries,
              },
              {
                field: "addr_zip",
                key: "addr_zip",
                label: "Postcode",
                placeholder: "Postcode",
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "azip",
              },
              {
                field: "addr_street",
                key: "addr_street",
                label: t("address-line-1"),
                placeholder: t("address-line-1"),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "addr1",
              },
              {
                field: "addr_line_2",
                key: "addr_line_2",
                label: t("address-line-2"),
                placeholder: t("address-line-2"),
                kind: "text",
                type: "text",
                needed: false,
                fieldId: "addr2",
              },
              {
                field: "addr_city",
                key: "addr_city",
                label: t("city-or-town"),
                placeholder: t("city-or-town"),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "city",
              },
              {
                field: "target_region",
                key: "target_region",
                label: t("what-geographic-region-you-will-be-mainly-targeting"),
                placeholder: t(
                  "what-geographic-region-you-will-be-mainly-targeting"
                ),
                kind: "text",
                type: "text",
                needed: true,
                fieldId: "tregion",
              },
              {
                field: "currency",
                key: "currency",
                label: t("Currency"),
                placeholder: t("Currency"),
                kind: "select",
                type: "select",
                needed: true,
                fieldId: "currency",
                choices: [
                  { value: "GBP", display_name: "GBP" },
                  { value: "USD", display_name: "USD" },
                  {
                    value: "EUR",
                    display_name: "EUR",
                  },
                ],
              },
              {
                field: "terms_and_conditions",
                key: "terms_and_conditions",
                label:
                  "I have read, understood and accepted the Privacy Policy, Risk Disclosures and Terms & Conditions",
                kind: "checkbox",
                type: "checkbox",
                needed: true,
                fieldId: "tconditions",
              },
            ],
          },
        ],
      ];
      if (this.whitelabel === "PolarisMarkets") {
        //   company fields to add : "company_registration_number", "company_nature_of_business:", "company_lei_number", "company_country_of_incorporation"
        const companyFields = [
          {
            field: "country_of_incorporation",
            key: "country_of_incorporation",
            label: "Country of Incorporation",
            placeholder: "Country of Incorporation",
            kind: "select",
            type: "select",
            needed: true,
            fieldId: "country_of_incorporation",
            choices: store.getters.get_countries,
          },
          {
            field: "company_registration_number",
            key: "company_registration_number",
            label: "Company Number",
            placeholder: "Company Registration Number",
            kind: "text",
            type: "text",
            needed: true,
            fieldId: "company_registration_number",
          },
          {
            field: "company_nature_of_business",
            key: "company_nature_of_business",
            label: "Nature of Business",
            placeholder: "Nature of Business",
            kind: "text",
            type: "text",
            needed: true,
            fieldId: "company_nature_of_business",
          },
          //   Do you Operate from a different
          // address

          {
            field: "company_operate_from_different_address",
            key: "company_operate_from_different_address",
            label:
              "Do you operate from a different\n" +
              "address than the one provided?",
            kind: "select",
            type: "select",
            needed: true,
            fieldId: "company_operate_from_different_address",
            choices: [
              { value: "yes", display_name: "Yes" },
              { value: "no", display_name: "No" },
            ],
          },
          {
            field: "company_operate_from_different_address_details",
            key: "company_operate_from_different_address_details",
            label: "Details of the different address?",
            placeholder: "Details of the different address",
            kind: "text",
            type: "select",
            needed: false,
            fieldId: "company_operate_from_different_address_details",
            choices: [
              { value: "yes", display_name: "Yes" },
              { value: "no", display_name: "No" },
            ],
          },
          {
            field: "company_lei_number",
            key: "company_lei_number",
            label: "LEI Number",
            placeholder: "LEI Number",
            kind: "text",
            type: "text",
            needed: true,
            fieldId: "company_lei_number",
          },
          //     regulatory fields
          {
            field: "company_authorised_activities",
            key: "company_authorised_activities",
            label:
              "Is the company authorised to action\n" +
              "regulated activities? ",

            kind: "select",
            type: "select",
            needed: true,
            fieldId: "company_authorised_activities",
            choices: [
              { value: "yes", display_name: "Yes" },
              { value: "no", display_name: "No" },
            ],
          },
          {
            field: "company_name_of_regulator",
            key: "company_name_of_regulator",
            label: "Name of Regulator",
            needed: false,
            placeholder: "Name of Regulator",
            kind: "text",
            type: "text",
            fieldId: "company_name_of_regulator",
          },
          {
            field: "company_type_of_license",
            key: "company_type_of_license",
            needed: false,
            label: "Type of License",
            placeholder: "Type of License",
            kind: "text",
            type: "text",
            fieldId: "company_type_of_license",
          },
          {
            field: "company_license_number",
            key: "company_license_number",
            needed: false,
            label: "License Number",
            placeholder: "License Number",
            kind: "text",
            type: "text",
          },
          {
            field: "company_political_exposed_person",
            key: "company_political_exposed_person",
            label:
              "Are any of the senior officers or\n" +
              "shareholders of the company\n" +
              "Politically Exposed Persons (PEPs)?",
            kind: "select",
            type: "select",
            needed: true,
            fieldId: "company_political_exposed_person",
            choices: [
              { value: "yes", display_name: "Yes" },
              { value: "no", display_name: "No" },
            ],
          },
          {
            field: "company_convicted_of_crime",
            key: "company_convicted_of_crime",
            label:
              "Have any of the senior officers of\n" +
              "the company ever been convicted of a\n" +
              "crime, which could potentially\n" +
              "disqualify the company from\n" +
              "registration?",
            kind: "select",
            type: "select",
            needed: true,
            fieldId: "company_convicted_of_crime",
            choices: [
              { value: "yes", display_name: "Yes" },
              { value: "no", display_name: "No" },
            ],
          },
        ];

        fields[0][0].fields = fields[0][0].fields.concat(companyFields);

        const personal_to_change = {
          field: "terms_and_conditions",
          key: "terms_and_conditions",
          label:
            "By submitting this form, I acknowledge and agree to the Terms of Business and related documents of POLARIS, recognizing that this constitutes a legally binding contractual agreement. I confirm that I fully comprehend the inherent nature and risks involved in trading Forex, CFDs, and other derivatives. Furthermore, I have thoroughly read and understood the Terms and Conditions, Order Execution Policy, Privacy Policy, and Risk Warning provided by POLARIS, which can be accessed online at www.polaris.com. By submitting this application, I consent to abide by the terms stated in these documents.<br/>\n<br/>\nAdditionally, I confirm the following:<br/>\n<br/>\nBy completing this application form and clicking the submit button, I confirm that I have read, understood, and agreed to the aforementioned statements. I acknowledge that my typed name in the designated field serves as my legal signature, equivalent to my handwritten signature.",

          kind: "checkbox",

          type: "checkbox",
          needed: true,
          fieldId: "tconditions",
        };
        //   find and replace
        const index = fields[1][0].fields.findIndex(
          (x) => x.fieldId === "tconditions"
        );
        fields[1][0].fields[index] = personal_to_change;
        console.log("fields", fields);

        function removeFields(fieldsArray, itemsToRemove) {
          itemsToRemove.forEach((item) => {
            const index = fieldsArray.findIndex((x) => x.field === item);
            if (index !== -1) {
              fieldsArray.splice(index, 1);
            }
          });
        }

        const remove = ["skype", "tax_id", "target_region", "currency"];
        removeFields(fields[0][0].fields, remove);
        removeFields(fields[1][0].fields, remove);

        console.log("fields", fields);

        return fields;
      }
      if (this.whitelabel === "Blackstone") {
        var confPass = fields[1][0].fields.findIndex(
          (x) => x.key === "currency"
        );
        var pass2 = {
          field: "currency",
          key: "currency",
          label: t("Currency"),
          placeholder: t("Currency"),
          kind: "select",
          type: "select",
          needed: true,
          fieldId: "currency",
          choices: [
            { value: "USD", display_name: "USD" },
            { value: "ZAR", display_name: "ZAR" },
          ],
        };
        fields[1][0].fields[confPass] = pass2;
      }
      if (this.whitelabel === "OneTradingMarkets") {
        let postcodeField = fields[0][0].fields.findIndex(
          (x) => x.key === "addr_zip"
        );
        fields[0][0].fields.splice(postcodeField, 1);

        if (this.whitelabel === "OneTradingMarkets") {
          let taxID = fields[0][0].fields.findIndex((x) => x.key === "tax_id");
          fields[0][0].fields.splice(taxID, 1);
        }

        let skypeField = fields[1][0].fields.findIndex(
          (x) => x.key === "skype"
        );
        fields[1][0].fields.splice(skypeField, 1);

        let postcodeField2 = fields[1][0].fields.findIndex(
          (x) => x.key === "addr_zip"
        );
        fields[1][0].fields.splice(postcodeField2, 1);

        let currencyKey = fields[1][0].fields.findIndex(
          (x) => x.key === "currency"
        );
        let currency2 = {
          field: "currency",
          key: "currency",
          label: t("Currency"),
          placeholder: t("Currency"),
          kind: "select",
          type: "select",
          needed: true,
          fieldId: "currency",
          choices: [{ value: "USD", display_name: "USD" }],
        };
        fields[1][0].fields[currencyKey] = currency2;

        let termAndCon = fields[1][0].fields.findIndex(
          (x) => x.key === "terms_and_conditions"
        );
        let termsAndCondition2 = {
          field: "terms_and_conditions",
          key: "terms_and_conditions",
          label:
            'I have read, understood and accepted the <a href="https://onetradingmarkets.com/privacy-policy-2/" target="_blank">Privacy Policy</a>, Risk Disclosures and <a href="https://onetradingmarkets.com/terms-conditions/" target="_blank">Terms & Conditions</a>',
          kind: "checkbox",
          type: "checkbox",
          needed: true,
          fieldId: "tconditions",
        };
        fields[1][0].fields[termAndCon] = termsAndCondition2;
      }
      if (
        this.whitelabel === "EagleGlobalMarkets" ||
        this.whitelabel === "TradeCoreUK"
      ) {
        let password2 = fields[1][0].fields.findIndex(
          (x) => x.key === "password2"
        );
        fields[1][0].fields.splice(password2, 1);

        let skypeField = fields[1][0].fields.findIndex(
          (x) => x.key === "skype"
        );
        fields[1][0].fields.splice(skypeField, 1);

        let currencyKey = fields[1][0].fields.findIndex(
          (x) => x.key === "currency"
        );
        let currency2 = {
          field: "currency",
          key: "currency",
          label: t("Currency"),
          placeholder: t("Currency"),
          kind: "select",
          type: "select",
          needed: true,
          fieldId: "currency",
          choices: [
            { value: "USD", display_name: "USD" },
            { value: "NGN", display_name: "NGN" },
          ],
        };
        fields[1][0].fields[currencyKey] = currency2;

        let termAndCon = fields[1][0].fields.findIndex(
          (x) => x.key === "terms_and_conditions"
        );
        let termsAndCondition2 = {
          field: "terms_and_conditions",
          key: "terms_and_conditions",
          label:
            'I have read, understood and accepted the <a href="https://www.eagleglobalmarkets.com/files/privacy-policy.pdf" target="_Blank">Privacy Policy</a>, <a href="https://www.eagleglobalmarkets.com/files/risk-warning-notice.pdf" target="_blank">Risk Disclosures</a> and <a href="https://eagleglobalmarkets.com/files/introducer-agreement-terms-and-conditions.pdf" target="_blank">Terms & Conditions</a>',
          kind: "checkbox",
          type: "checkbox",
          needed: true,
          fieldId: "tconditions",
        };
        fields[1][0].fields[termAndCon] = termsAndCondition2;
      }
      if (this.whitelabel === "GCCBrokers") {
        let termAndCon = fields[1][0].fields.findIndex(
          (x) => x.key === "terms_and_conditions"
        );
        let termsAndCondition2 = {
          field: "terms_and_conditions",
          key: "terms_and_conditions",
          label: t(
            "i-have-read-understood-and-accepted-the-privacy-policy-risk-disclosures-and-terms-and-conditions"
          ),
          kind: "checkbox",
          type: "checkbox",
          needed: true,
          fieldId: "tconditions",
        };
        fields[1][0].fields[termAndCon] = termsAndCondition2;
      }
      return fields;
    },
  },

  created() {
    this.$store.dispatch("countries");
  },
};
</script>
