<template>
  <div class="ib-company-register-vue">
    <Navigation
      :show-language-switcher="true"
      :show-user-menu="false"
      :showLogin="true"
    />

    <company-register-finsa
      v-if="
        whitelabel === 'TD365' ||
        whitelabel === 'TDSouthAfrica' ||
        whitelabel === 'Blackstone' ||
        whitelabel === 'OneTradingMarkets' ||
        whitelabel === 'EagleGlobalMarkets' ||
        whitelabel === 'TradeCoreUK' ||
        whitelabel === 'PolarisMarkets'
      "
    >
    </company-register-finsa>
    <company-register-icm v-else></company-register-icm>

    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import Navigation from "@/components/Navigation";
import CompanyRegisterIcm from "@/views/ib/register/CompanyRegisterIcm";
import CompanyRegisterFinsa from "@/views/ib/register/CompanyRegisterFinsa";

export default {
  name: "CompanyForms",
  data() {
    return {
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };
  },
  components: {
    Footer,
    Navigation,
    CompanyRegisterIcm,
    CompanyRegisterFinsa,
  },
};
</script>
